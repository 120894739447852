.PI__ResultsCard {
    padding: .5rem;
}
.PI__ResultsCard p{
    font-family: var(--font-family);
    color: var(--color-text);
}

.PI__ResultsCard a{
    color:var(--color-subtext);
}

.PI__ResultsCard a:hover{
    font-weight: 600;
    cursor: pointer;
}

.PI__ResultsCard-close{
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 1rem;
}