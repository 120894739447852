.PI__NewsElement {
    display: inline-block;
    font-family: var(--font-family);
    color:var(--color-text);
    margin: 1rem .5rem;
}

.PI__NewsElement-title p{
    font-family: var(--font-family);
    font-size: 18px;
    color:white;
}

.PI__NewsElement-content {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-right: 1rem;
   
}

.PI__NewsElement-date {
    font-size: 14px;
    color:rgb(148, 148, 148)
}

.PI__NewsElement-newsIcon {
    width: 100px;
    height: 100px;
    float: left;
    
}

.PI__NewsElement-newsIcon img{
    width: 100%;
    height: auto;
    max-width: 75px;
    max-height: 75px;
}

.PI__NewsElement-link{
    font-size: 14px;
    padding: 1rem .5rem;
    text-align: right;
    font-weight: 300;
    color: var(--color-subtext);
}

.PI__NewsElement-link:hover{
    font-weight: 400;
    /*text-decoration: underline;*/
}

