.PI__Review{
    position: relative;
    display: inline-block;
   /* border: 1px solid blue;*/
    margin-bottom: 1rem;
    
}

.PI__Review_image{
    display: inline-block;
    width:50px;
    
}

.PI__Review_image img{
    width: 100%;
    height: auto;
}

.PI__Review_content{
    width: calc(100% - 50px);
    display: inline-block;
    text-align: left;
    vertical-align: top;
    


    /*border: 1px solid blue;*/
}

.PI__Review_content p{

    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: auto;
    padding: 1rem;
    

}