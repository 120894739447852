
.PI__Mission {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    
}

.PI__Mission-image{
    flex: .5;
    align-items: baseline;
    /*justify-content:end;*/
    /*align-items: center;*/
}

.PI__Mission-image img{
   
    /*object-fit:scale-down;*/
    object-fit: none;
    width: 100%;
    height: 100%;
    
}

.PI__Mission-content{
   /* border: 1px solid #ffcc80;*/
   flex: 1;
   display: block;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
   margin: 1rem .5rem;
}
.PI__Mission h1{
    padding-left: 2rem;
    text-align: left;
    font-family: var(--font-family);
    /*background: var(--gradient-bar);*/
    color: rgb(0, 0, 0);
}

.PI__Mission p{
    padding: .5rem 2rem;
    text-align: justify;
    font-family: var(--font-family);
    color: var(--color-text);
}

@media screen and (min-width: 550px) {
    .PI__Mission {
        flex-direction: row;
    }
    .PI__Mission-image{
        min-width: 200px;
    }
    .PI__Mission-content{
        margin: 1rem 0;
    }
}