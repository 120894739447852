

.PI__Products-content{
    /*border: 1px solid #ffcc80;*/
    display: flex;
    margin:0 1rem;
    
}

@media screen and (max-width:550px) {
    .PI__Products {
        padding: 1rem;
    }
    
    .PI__Products-content{
        display: block;
    }
    
}