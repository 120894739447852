.PI__Map h1 {
    color:var(--font-color);
    color:white;
}

.PI__Map-container {
    position: relative;
    display:inline-block;
    width:100%;
    
    
}

.PI__Map-container_list{
    position:absolute;
    /*display: none;*/
    width:100%;
    height: 100%;
    background-color: var(--color-blog);
    z-index: 100;
    transition: all .5s;
}
.PI__Map-container_map{
    position: relative;
    width:100%;
    height: calc(100vh - 200px);
}

.PI__Hide{
    /*display: none;*/
    left:-450px;
}

.PI__Show{
    left:0px;
}



@media screen and (min-width:550px) {
    
    .PI__Map-container_list{
    width:350px;

    }
}