.PI__Article{
    position: relative;
    display: inline-block;
    margin:0;
    width:250px;
    height: 300px;

    border: 1px solid #ffcc802a;
    margin: 0 1rem;
    vertical-align: top;
}

.PI__Article img{
    width: 248px;  
    height: 100px;
}

.PI__Article h2,
.PI__Article p{
    font-family: var(--font-family);
    color: white;
}

.PI__Article h2 {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 20px;
    left: 0;
    right: 0;
    background:rgba(0, 0, 0, 0.603);
    font-size: 32px;
    text-align: center;
    
}

.PI__Article p{
    padding: 1rem .5rem;
    text-align: justify;
}
