.PI__Contact {
    text-align: center;
}
.PI__Contact h1 {
    text-align: center;
    margin: 1rem;
    
}

.PI__Article{
    border:none;
    background: var(--color-blog);

    box-shadow: 1px 9px 15px 3px rgba(0,0,0,0.22);
    -webkit-box-shadow: 1px 9px 15px 3px rgba(0,0,0,0.22);
    -moz-box-shadow: 1px 9px 15px 3px rgba(0,0,0,0.22);
    margin-bottom: 1rem;
}

.PI__Article a{
    /*border:none;*/
    text-align: center;
    font-family: var(--font-family);
    color: var(--color-subtext);
}

.PI__Article p{
    /*border:none;*/
    text-align: center;
    font-family: var(--font-family);
    /*color: var(--color-subtext);*/
}

.PI__Article a:hover{
    font-weight: bolder;
}

.PI__Article img{
    display: block;
    height: auto;
    width: 150px;
    margin: auto;
    padding-top: 50px;
}

.PI__Article h2{
    position: absolute;
    top: 0;
    background: rgb(1,0,19);
    background: linear-gradient(180deg, var(--color-bg), rgba(0,0,0,0) 100%);
    padding-bottom: 1rem;
    
}