.PI__slideshowItem {
    display: none;
    max-height: 400px;
    max-width: 800px;
}

.PI__slideshowItem img {
    /*max-height: 180px;*/
    width: auto;
    max-height: 400px;
}

.PI__slideshowItem-show {
    display:inline-block;
}

.PI__slideshowItem-hide{
    display: none;
}
