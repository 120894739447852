.PI__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;
    background: var( --color-bg);/**/
    transition: all .5s;
}

.PI__navbar-links {
    flex: 1;
    display: flex;
    /*justify-content: flex-start;*/
    justify-content: center;
    align-items: center;
    
}

.PI__navbar-links_logo{
    margin-right: 2rem;
}

.PI__navbar-links_logo img{
    width:auto;
    height: 50px;
    
}

.PI__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.PI__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.PI__navbar-donate{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.PI__navbar-links_container p,
.PI__navbar-sign p,
.PI__navbar-menu_container p{
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.PI__navbar-sign button,
.PI__navbar-menu_container button,
.PI__navbar-donate button,
.PI__navbar-menu_container-links-donate button
{
    padding: 0.5rem 1rem;
    color:white;
    background:#ff4820;
    font-family:var(--font_family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.PI__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.PI__navbar-menu svg{
    cursor:pointer;
}

.PI__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var( --color-bg);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    border-color: var(--color-text);
    border-width: 0 4px 1px 1px;
    border-style: outset;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    z-index: 100000;
    
}

.PI__navbar-menu_container p{
    margin: 1rem 0;
}

.PI__navbar-menu_container-links-sign {
    display:none;
}


@media screen and (max-width: 1050px) {
    
    
}

@media screen and (max-width: 700px) {
    /*.PI__navbar {
        padding: 2rem 4rem;
    }*/
}

@media screen and (max-width: 550px) {
    .PI__navbar-links_container{
        display:none;
    }
    
    .PI__navbar-menu{
        display: flex;
    }
    /*.PI__navbar {
        padding: 2rem;
    }*/
/*
    .PI__navbar-sign{
        display:none;
    }*/

    .PI__navbar-menu_container{
        top: 15px;
    }
/*
    .PI__navbar-menu_container-links-sign{
        display: block;
    }*/
}