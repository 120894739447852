.PI__Reviews {
    display: flex;
    flex-direction: row;
}

.PI__Reviews-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.PI__Reviews-image img {
    width: 100%;
    height: 100%;

}

.PI__Reviews-content {
    flex: 1;
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: column;

    /*border: 1px solid rgb(32, 123, 207);*/
}


@media screen and (max-width: 950px) {
    .PI__Reviews {
        flex-direction: column;
    }

    .PI__Reviews-image {
        margin: 1rem 0;
    }

    .PI__Reviews-content {
        margin-top: 2rem;
    }
}


