.PI__slideshow-container {

    display: flex;
    
    justify-content: center;     

    /*background-color: #bbdefb;*/
    height: 100%;
    padding: 15px;
    gap: 5px;

  }

  .PI__slideshow-container > div{
    /*background: #ffecb3;*/
    border: 1px solid #ffcc80;
    border-radius: 5px;
    padding: 8px;
  }


  .PI__slideshow-container_previous,
  .PI__slideshow-container_next { 
    /* flex:0 1 auto; */
    flex-shrink:1;
    align-self:center;
    color:#FFF;
    font-weight: bolder;
    font-size: 20px;
  }
			
.PI__slideshow-container_content { 
    /* flex:1 8 auto; */
    flex-grow:1;
    flex-shrink:8;
    justify-items: center;
    text-align: center;
    

    position: relative;
}

.PI__slideshow-container_content-slides{
    
    display:inline-block;
    justify-content: center;
    width:100%;
    height:100%;
    margin-bottom: 10px;
    /*width: 300px;*/


}

.PI__slideshow-pagenation{
    position: absolute;
    bottom:0;
    left: 0;
    right: 0;
    border: 1px solid #ffcc80;
    text-align: center;
   
}

.PI__slideshow-pagenation p{
    display:inline-block;
    color:#FFF;
    padding: 0 .25rem;
    cursor: pointer;


}

  