.PI__Intro {
    display: flex;
    flex-direction: column;
}

.PI__Intro-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 1rem;
    
}

.PI__Intro-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.04em;
    text-align: center;
    justify-content: center;
    margin: auto;
    
}

.PI__Intro-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
    text-align: justify;
}

.PI__Intro-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.PI__Intro-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.PI__Intro-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.PI__Intro-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.PI__Intro-content__people img {
    width: 181.79px;
    height: 38px;
}

.PI__Intro-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.PI__Intro-image-full {
    flex: 1;
    display: none;
    justify-content: center;
    align-items: center;
    
    
    
}
.PI__Intro-image-header {
    display: block;
    justify-content: center;
    align-items: center;
    height:200px
    
}
.PI__Intro-image-header img{
    width: 100%;
    height: 100%;
    object-fit: none;
}

.PI__Intro-image-full img
{
    width: 100%;
    height: 100%;
    object-fit:scale-down;
    max-height: 448px;

}

@media screen and (min-width: 460px) {
    .PI__Intro-content h1 {
        font-size: 40px;
    }
}

@media screen and (min-width: 550px) {
    
    .PI__Intro-content h1 {
        font-size: 48px;
        line-height: 48px;
        text-align: left;
        margin-top: 1rem;
    }

    .PI__Intro-content p {
        font-size: 22px;
        line-height: 24px;
    }

    .PI__Intro-content__input input,
    .PI__Intro-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (min-width: 650px) {
    .PI__Intro-content h1 {
        font-size: 64px;
        line-height: 60px;
    }

    .PI__Intro-content p {
        font-size: 24px;
        line-height: 24px;
    }
    
    .PI__Intro-content__people {
        flex-direction: column;
    }

    .PI__Intro-content__people p {
        margin: 0;
    }

    .PI__Intro-content__input input,
    .PI__Intro-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (min-width: 1050px) {
    .PI__Intro {
        flex-direction: row;
    }

    .PI__Intro-content {
        margin-right: 5rem;
    }
    .PI__Intro-content p {
        font-size: 28px;
        line-height: 32px;
            
        margin-top: 1.5rem;
        text-align: justify;
    }
    .PI__Intro-content h1 {
        font-size: 48px;
    }

    .PI__Intro-image-full{
        display: flex;
    }
    .PI__Intro-image-header{
        display: none;
    }
}