.PI__News{
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: 2rem;
}

.PI__News-title p{
    font-family: var(--font-family);
    font-size: 28px;
    font-weight: 900;
    text-align: center;
}

.PI__News-bar {
    width: 100%;
    height: 2px;
    background-image: var(--gradient-bar);

}

@media screen and (min-width: 550px) {
    .PI__News{
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media screen and (min-width: 720px) {
    .PI__News{
        margin-left: 4rem;
        margin-right: 4rem;
    }
}

@media screen and (min-width: 1080px) {
    .PI__News{
        margin-left: 6rem;
        margin-right: 6rem;
    }
}